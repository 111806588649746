import request from '@/utils/request'

// 分页查询设备编号密钥对
export function pageSecret(query) {
  return request({
    url: '/device/secret/page',
    method: 'get',
    params: query
  })
}

// 查询设备编号密钥对列表
export function listSecret(query) {
  return request({
    url: '/device/secret/list',
    method: 'get',
    params: query
  })
}

// 删除设备编号密钥对
export function delSecret(id) {
  return request({
    url: '/device/secret/' + id,
    method: 'delete'
  })
}

//根据生成信息删除
export function delGenerate(id){
  return request({
    url: '/device/secret/generate/' + id,
    method: 'delete'
  })
}


export function generate(data) {
  return request({
    url: '/device/secret/generate',
    method: 'post',
    data: data
  })
}

export function append(data) {
  return request({
    url: '/device/secret/append',
    method: 'post',
    data: data
  })
}


export function generatePage(query) {
  return request({
    url: '/device/secret/generate/page',
    method: 'get',
    params: query
  })
}

export function generateQuery(id) {
  return request({
    url: '/device/secret/generate/' + id,
    method: 'get'
  })
}
