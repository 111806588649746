<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item v-if="this.source === 'DEVICE_GROUP'" label="分组" prop="groupId">
        <el-select v-model="queryParams.groupId" placeholder="选择分组" clearable @change="getList" id="groupId">
          <el-option
            v-for="item in deviceGroups"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="厂商型号" prop="qCompanyModelType">
        <el-cascader
          style="width: 250px"
          v-model="qCompanyModelType"
          :options="companyModelTypeTree"
          :props="{ checkStrictly: true }"
          @change="this.handleQuery"
          clearable>
        </el-cascader>
      </el-form-item>
      <el-form-item label="SN" prop="number">
        <el-input
          class="sf-middle"
          v-model="queryParams.number"
          placeholder="请输入SN"
          clearable
          @change="this.handleQuery"
          id="number"
        />
      </el-form-item>
      <el-form-item v-if="source === 'DEVICE_GROUP'" label="" prop="noGroup">
        <el-checkbox v-model="queryParams.noGroup" @change="groupStatus" label="不在组中" border size="small"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col v-if="false" :span="1.5">
        <el-button
          class="text-success"
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['device:device:add']"
        >新增</el-button>
      </el-col>
      <el-col v-if="source === undefined" :span="1.5">
        <el-button
          class="text-danger"
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['device:device:remove']"
        >删除</el-button>
      </el-col>
      <el-col v-if="source === undefined" :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['device:device:export']"
        >导出</el-button>
      </el-col>
      <el-col v-if="false" :span="1.5">
        <el-button
          type="info"
          plain
          icon="el-icon-upload2"
          size="mini"
          @click="handleImport"
          v-hasPermi="['system:user:import']"
        >导入</el-button>
      </el-col>
      <el-col v-if="source === 'DEVICE_GROUP'" :span="1.5">
        <el-button
          class="text-warning"
          type="warning"
          plain
          icon="el-icon-pie-chart"
          size="mini"
          @click="handleLeaveGroup"
          :disabled="disableLeaveBtn"
          v-hasPermi="['group:device:leave']"
        >退出组</el-button>
      </el-col>
      <el-col v-if="source === 'DEVICE_GROUP'" :span="1.5">
        <el-button
          class="text-success"
          type="success"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleJoinGroup"
          :disabled="multiple"
          v-hasPermi="['group:device:join']"
        >加入组</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="deviceList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="SN" align="center" prop="number" />
      <el-table-column label="绑定用户数" align="center">
        <template slot-scope="scope">
          <el-link disabled v-if="scope.row.bindUserCount === 0">0</el-link>
          <el-link v-else @click="displayBindUser(scope.row.id)">{{scope.row.bindUserCount}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="试剂上报记录" align="center" prop="reagentLogs">
        <template slot-scope="scope">
          <el-link disabled v-if="scope.row.reagentLogCount === 0">0</el-link>
          <el-link v-else @click="displayReagentLog(scope.row.id)">{{scope.row.reagentLogCount}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="LastTestId" align="center" prop="lastTestId" />
      <el-table-column label="产品厂商" align="center" prop="companyName" />
      <el-table-column label="产品系列" align="center" prop="modelName" />
      <el-table-column label="产品型号" align="center" prop="typeName" />
      <el-table-column v-if="source === 'DEVICE_GROUP'" label="设备组" align="center" prop="groupName" />
      <el-table-column v-if="false" label="设置页面地址" align="center" prop="setPage" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            v-if="false"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['device:device:edit']"
          >修改</el-button>
          <el-button
            v-if="source === undefined"
            class="text-warning"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['device:device:remove']"
          >删除</el-button>
          <el-button
            class="text-warning"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleResetLTI(scope.row)"
            v-hasPermi="['device:device:resetLastTestId']"
          >设置LastTestId</el-button>
          <el-button
            v-if="source === 'DEVICE_GROUP'"
            class="text-warning"
            size="mini"
            type="text"
            icon="el-icon-pie-chart"
            :disabled="!scope.row.groupId"
            @click="handleLeaveGroup(scope.row)"
            v-hasPermi="['group:device:leave']"
          >退组</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <!-- 添加或修改设备列表对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="厂商型号" prop="companyModel">
          <el-cascader
              v-model="form.companyModel"
              :options="companyModelTree"
              style="width: 250px"
              clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="SN" prop="number">
          <el-input v-model="form.number" placeholder="请输入SN" />
        </el-form-item>
<!--        <el-form-item label="设备名" prop="name">-->
<!--          <el-input v-model="form.name" placeholder="请输入设备名" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="设置页" prop="setPage">-->
<!--          <el-input v-model="form.setPage" placeholder="请输入设置页面地址" />-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport + '&autoRegister=' + upload.autoRegister"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip text-center" slot="tip">
          <div class="el-upload__tip" slot="tip">
            <el-checkbox v-model="upload.updateSupport" label="更新已存在的数据" border size="mini"></el-checkbox>
            <el-checkbox v-model="upload.autoRegister" label="注册不存在的型号" border  size="mini"></el-checkbox>
          </div>
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="margin-top:15px;font-size:12px;vertical-align: baseline;" @click="importTemplate">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>

    <!--添加到组弹出框-->
    <el-dialog title="添加到组" :visible.sync="dialogJoinGroup" width="360px">
      <el-form ref="joinGroupForm" :model="joinGroupForm" :rules="joinGroupRules">
        <el-select v-model="joinGroupForm.id" placeholder="选择分组">
          <el-option
            v-for="item in calDeviceGroups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            :disabled="item.disabled"
          >
          </el-option>
        </el-select>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogJoinGroup = false">取 消</el-button>
        <el-button type="primary" @click="submitJoinGroupForm">确 定</el-button>
      </div>
    </el-dialog>

    <el-drawer
      title="试剂卡上报记录"
      :visible.sync="showReagentLog"
      direction="rtl"
      size="20%">
      <el-table :data="reagentLogs">
        <el-table-column property="reagentNumber" label="批号" width="120"></el-table-column>
        <el-table-column property="codeIndex" label="盒号" width="80"></el-table-column>
        <el-table-column property="createTime" label="时间" width="180"></el-table-column>
      </el-table>
    </el-drawer>

    <el-drawer
      title="设备绑定记录"
      :visible.sync="showBindLog"
      direction="rtl"
      size="300">
      <el-table :data="bindLogs">
        <el-table-column property="createTime" label="绑定时间" width="160"></el-table-column>
        <el-table-column property="user.name" label="绑定用户" width="200"></el-table-column>
        <el-table-column property="parentId" label="绑定方式" width="120">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.parentId === 0">自主绑定</el-tag>
            <el-tag type="success" v-else>权限分配</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>

    <!--重置LastTestId弹出框-->
    <el-dialog title="设置LastTestId" :visible.sync="dialogResetLTI" width="360px">
      <el-form ref="resetLTIForm" :model="resetLTIForm" :rules="resetLTIRules">
        <el-form-item hidden label="id" prop="id">
          <el-input hidden v-model="resetLTIForm.id" disabled />
        </el-form-item>
        <el-form-item label="SN" prop="number">
          <el-input v-model="resetLTIForm.number" disabled placeholder="请输入SN" />
        </el-form-item>
        <el-form-item label="lastTestId" prop="lastTestId">
          <el-input v-model="resetLTIForm.lastTestId" placeholder="请输入lastTestId" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogResetLTI = false">取 消</el-button>
        <el-button type="primary" @click="submitResetLTIForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { addDevice, delDevice, getDevice, pageDevice, updateDevice, getDeviceBindLog, getDeviceReagentUpLog, resetLastTestId } from '@/api/device/device'
import { camTreeSelect as getCompanyModelTree, optionSelect as getCompanyOptionSelect } from '@/api/company/company'
import { cmtTreeSelect as getCompanyModelTypeTree } from '@/api/device/device'
import { joinGroup, leaveGroup, listGroup } from '@/api/common/group'
import Link from "../../../layout/components/Sidebar/Link.vue";

export default {
  name: "Device",
  components: {Link},
  dicts: ['device_bind_openning'],
  data() {
    const validateNumberInput = (rule, value, callback) => {
      // 如果用户没有输入，则通过校验
      if (!value) {
        return callback();
      }
      // 检查是否为1-9999的正整数
      const pattern = /^([0-9][0-9]{0,7})$/;
      if (!pattern.test(value)) {
        return callback(new Error('请输入1-999999999的正整数'));
      }
      // 校验通过
      callback();
    }
    return {
      resetLTIForm:{
        id: null,
        number: '',
        lastTestId: null,
      },
      dialogResetLTI: false,
      queryCompanyModelType: [],
      showBindLog: false,
      bindLogs: [],
      showReagentLog: false,
      reagentLogs: [],
      selected: false,
      joinGroupForm: {
        id: null,
        memberIds: []
      },
      dialogJoinGroup: false,
      formLabelWidth: '120px',
      source: '',
      sourceId: 0,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备列表表格数据
      deviceList: [],
      companyModelTypeTree: [],
      qCompanyModelType: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 设备导入参数
      upload: {
        // 是否显示弹出层（设备导入）
        open: false,
        // 弹出层标题（设备导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的设备数据
        updateSupport: 0,
        //是否自动注册厂商、型号
        autoRegister: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/device/device/importData"
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        number: null,
        name: null,
        openness: null,
        groupId: null,
        deviceGroupType: '',
        noGroup: null
      },
      // 表单参数
      form: {},
      // 厂商数据字典
      companyOptions: [],
      companyMap: {},
      companyModelTree: [],
      //分组数据
      deviceGroups: [],
      deviceGroupMap: {},
      // 表单校验
      rules: {
        companyModel: [
          { required: true, message: "厂商型号不能为空", trigger: "blur" }
        ],
        number: [
          { required: true, message: "SN不能为空", trigger: "blur" }
        ]
      },
      joinGroupRules: {
        groupId: [
          { required: true, message: "请选择分组", trigger: "blur" }
        ],
        memberIds: [
          { required: true, message: "选择设备", trigger: "submit" }
        ]
      },
      resetLTIRules: {
        lastTestId: [
          { validator: validateNumberInput, trigger: 'blur' }
        ]
      },
    };
  },
  computed: {
    disableLeaveBtn() {
      return !this.selected || !this.queryParams.groupId
    },
    calDeviceGroups() {
      let groups = this.deviceGroups;
      for (let i = 0; i < groups.length; i++) {
        groups[i].disabled = groups[i].id === this.queryParams.groupId;
      }
      return groups;
    }
  },
  watch: {

  },
  created() {
    let companyModelTypeParam = this.$route.params && this.$route.params.companyModelType;
    this.qCompanyModelType = companyModelTypeParam === undefined ? [] : companyModelTypeParam;
    this.source = this.$route.params && this.$route.params.source;
    this.sourceId = this.$route.params && this.$route.params.sourceId;
    this.getCompanyList();
    this.getCompanyModelTree();
    this.getCompanyModelTypeTree();
    if (this.source === 'DEVICE_GROUP'){
      this.getGroupList();
    }
    this.getList();
  },
  methods: {
    handleResetLTI(device){
      this.dialogResetLTI = true;
      this.resetLTIForm.id = device.id
      this.resetLTIForm.number = device.number
      this.resetLTIForm.lastTestId = device.lastTestId;
    },
    submitResetLTIForm(){
      resetLastTestId(this.resetLTIForm).then(response => {
        this.$modal.msgSuccess("设置成功");
        this.dialogResetLTI = false;
        this.getList();
      })
    },
    displayBindUser(did){
      getDeviceBindLog(did).then(response => {
        this.showBindLog = true
        this.bindLogs = response.data
      })
    },
    displayReagentLog(did) {
      getDeviceReagentUpLog(did).then(response => {
        this.showReagentLog = true
        this.reagentLogs = response.data
      })
    },
    //获取厂商系列类型数
    getCompanyModelTypeTree() {
      getCompanyModelTypeTree(1).then(response => {
        this.companyModelTypeTree = response.data
      })
    },
    groupStatus(checked) {
      if (checked){
        this.queryParams.groupId = null
      }
      this.getList();
    },
    getGroupList() {
      this.queryParams.groupId = parseInt(this.sourceId)
      listGroup().then(response => {
        this.deviceGroups = response.data
        for (let i = 0; i < response.data.length; i++) {
          this.deviceGroupMap[response.data[i].id] = response.data[i].name;
        }
      })
    },
    /** 查询字典类型列表 */
    getCompanyList() {
      getCompanyOptionSelect().then(response => {
        this.companyOptions = response.data;
        for (let i = 0; i < response.data.length; i++) {
          this.companyMap[response.data[i].id] = response.data[i].name;
        }
      });
    },
    /** 查询厂商型号树 */
    getCompanyModelTree() {
      getCompanyModelTree().then(response => {
        this.companyModelTree = response.data;
      });
    },
    /** 查询设备列表列表 */
    getList() {
      this.loading = true;
      if (this.qCompanyModelType !== undefined){
        if (this.qCompanyModelType[0] !== undefined){
          this.queryParams.companyId = this.qCompanyModelType[0];
        }else {
          delete this.queryParams.companyId;
        }
        if (this.qCompanyModelType[1] !== undefined){
          this.queryParams.companyModelId = this.qCompanyModelType[1];
        }else {
          delete this.queryParams.companyModelId;
        }
        if (this.qCompanyModelType[2] !== undefined){
          this.queryParams.companyModelTypeId = this.qCompanyModelType[2];
        }else {
          delete this.queryParams.companyModelTypeId;
        }
      }
      this.queryParams.deviceGroupType = this.queryParams.noGroup ? 'NONE' : this.source;
      pageDevice(this.queryParams).then(response => {
        this.deviceList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        companyModelId: null,
        number: null,
        name: null,
        openness: null
      };
      this.resetForm("form");
    },
    resetJoinForm() {
      this.joinGroupForm = {
        id: null,
        memberIds: []
      };
      this.resetForm("joinGroupForm");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.qCompanyModelType = []
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
      this.selected = selection.length > 0
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加设备列表";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getDevice(id).then(response => {
        this.form = response.data;
        this.form.companyModel = [this.form.companyId,this.form.companyModelId]
        this.open = true;
        this.title = "修改设备列表";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.companyId = this.form.companyModel[0];
          this.form.companyModelId = this.form.companyModel[1];
          if (this.form.id != null) {
            updateDevice(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addDevice(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    submitJoinGroupForm(row) {
      this.$refs["joinGroupForm"].validate(valid => {
        if (valid) {
          this.joinGroupForm.memberIds = row.id || this.ids;
          joinGroup(this.joinGroupForm).then(response => {
            this.$modal.msgSuccess("新增成功");
            this.dialogJoinGroup = false;
            this.getList();
          });
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      let msg = row.id === undefined ? '是否确认删除选中的'+ids.length+'条数据？' : '是否确认删除编号['+row.number+']的设备？';
      this.$modal.confirm(msg).then(function() {
        return delDevice(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 移除出组 */
    handleLeaveGroup(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认将设备移除出组').then(function() {
        return leaveGroup({ type: 'DEVICE_GROUP', memberIds: ids });
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("移除成功");
      }).catch(() => {});
    },
    /** 添加到组 */
    handleJoinGroup() {
      this.resetJoinForm();
      this.dialogJoinGroup = true;
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('device/device/export', {
        ...this.queryParams
      }, `device_${new Date().getTime()}.xlsx`)
    },
    /** 导入按钮操作 */
    handleImport() {
      this.upload.title = "设备导入";
      this.upload.open = true;
      this.upload.autoRegister = false;
      this.upload.updateSupport = false;
    },
    /** 下载模板操作 */
    importTemplate() {
      this.download('device/device/importTemplate', {
      }, `设备导入_${new Date().getTime()}.xlsx`)
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
      this.getList();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    }
  }
};
</script>
