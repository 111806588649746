import request from '@/utils/request'

// 查询设备列表列表
export function pageDevice(query) {
  return request({
    url: '/device/page',
    method: 'get',
    params: query
  })
}

// 查询设备列表详细
export function getDevice(id) {
  return request({
    url: '/device/' + id,
    method: 'get'
  })
}

// 新增设备列表
export function addDevice(data) {
  return request({
    url: '/device',
    method: 'post',
    data: data
  })
}

// 修改设备列表
export function updateDevice(data) {
  return request({
    url: '/device',
    method: 'put',
    data: data
  })
}

// 删除设备列表
export function delDevice(id) {
  return request({
    url: '/device/' + id,
    method: 'delete'
  })
}

// 获取厂商型号树
export function cmtTreeSelect(noneLeaf) {
  return request({
    url: '/device/cmtTreeSelect/'+noneLeaf,
    method: 'get'
  })
}
// 系列型号树
export function modelTypeTree() {
  return request({
    url: '/device/modelTypeTree',
    method: 'get'
  })
}
//获取已配置的系列型号树配置
export function configuredModelTypeTree() {
  return request({
    url: '/device/configuredModelTypeTree',
    method: 'get'
  })
}

export function getDeviceBindLog(deviceId) {
  return request({
    url: '/device/bindLog/' + deviceId,
    method: 'get'
  })
}

export function getDeviceReagentUpLog(deviceId) {
  return request({
    url: '/device/reagentUpLog/' + deviceId,
    method: 'get'
  })
}

// 新增设备列表
export function resetLastTestId(data) {
  return request({
    url: '/device/resetLastTestId',
    method: 'post',
    data: data
  })
}
