import request from '@/utils/request'

// 获取用户详细信息
export function getStatisticsData() {
  return request({
    url: '/common/getStatisticsData',
    method: 'get'
  })
}

// 获取服务器环境
export function getEnv() {
  return request({
    url: '/common/getEnv',
    method: 'get'
  })
}

//表格格式化小数
export function rounding(row,column) {
  return parseFloat(row[column.property]).toFixed(2)
}

export function arrToStr(row,column){
  return row[column.property].toString()
}
