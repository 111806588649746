<template>
  <div class="dashboard-editor-container">
<!--    <el-button @click="handleTest()">Test</el-button>-->
<!--    <el-cascader-->
<!--      ref="cascader"-->
<!--      size="large"-->
<!--      clearable-->
<!--      :options="pcTextArr"-->
<!--      :props="props"-->
<!--      v-model="searea"-->
<!--      @change="handleChange"-->
<!--    >-->
<!--    </el-cascader>-->
    <panel-group
        :total-user="this.totalUser"
        :total-device="this.totalDevice"
        :total-report="this.totalReport"
        :total-article="this.totalArticle"
        @handleSetLineChartData="handleSetLineChartData"
    />

    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;">
      <line-chart :chart-data="lineChartData" />
    </el-row>


  </div>
</template>

<script>
import PanelGroup from './dashboard/PanelGroup'
import LineChart from './dashboard/LineChart'
import { getStatisticsData } from "@/api/common";
import { test } from '@/api/common/test'
import axios from 'axios';
import { parseTime } from '@/utils/seed'
import { addProject, updateProject } from '@/api/reagent/checkDesc'
import { regionData, CodeToText, TextToCode,pcTextArr, provinceAndCityData } from "element-china-area-data";

let lineChartDataList = {
  user: {
    countTitle: [],
    horizontalAxis: [],
    countData: [[]],
  }
}

export default {
  name: 'Index',
  components: {
    PanelGroup,
    LineChart,
  },
  data() {
    return {
      totalUser: [],
      totalDevice: [],
      totalReport: [],
      totalArticle: [],
      lineChartData: lineChartDataList.user,
      options: regionData,
      searea: [
        12,13,[12,120103]
      ],
      props: { multiple: true, checkStrictly: true },
      provinceAndCityData,
      selectedOptions: [],
      pcTextArr
    }
  },
  created() {
    // this.handleSetLineChartData();
    this.getRemoteData();

  },
  methods: {
    //城市选择
    handleChange(e) {
      for (let i = 0; i < e.length; i++) {
        // console.log(e[i])
      }
      //直接输出数组
      //可以转换为对应的城市编码TextToCode，或将城市转换为名称CodeToText
      console.log(this.searea)
    },
    handleTest() {
      test('123').then(response => {
        this.$modal.msgSuccess("执行完成");
      });
      // let url = 'http://localhost:9528/test/vt/code'
      // const a = document.createElement('a')
      // a.href = url
      // a.download = 'test.zip' // 下载后文件名
      // a.style.display = 'none'
      // document.body.appendChild(a)
      // a.click() // 点击下载
      // document.body.removeChild(a) // 下载完成移除元素
    },
    handleSetLineChartData(type) {
      this.lineChartData = lineChartDataList[type]
    },
    getRemoteData() {
      getStatisticsData().then(response => {
        const allCharData = response.data;
        this.lineChartData = allCharData.user;
        this.totalUser = allCharData.user.totalCount
        this.totalDevice = allCharData.device.totalCount
        this.totalReport = allCharData.report.totalCount
        this.totalArticle = allCharData.article.totalCount
        lineChartDataList = allCharData
      });
    }
  },
  mounted() {
    //城市选择配置
    setInterval(function() {
      document.querySelectorAll(".el-cascader-node__label").forEach(el => {
        el.onclick = function() {
          if (this.previousElementSibling) this.previousElementSibling.click();
        };
      });
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
