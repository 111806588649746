<template>
  <div class="app-container">
    <el-card shadow="always" style="margin-bottom: 10px">
      {{ this.generateInfo.desc }}
    </el-card>
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
    </el-form>

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--          type="danger"-->
<!--          plain-->
<!--          icon="el-icon-delete"-->
<!--          size="mini"-->
<!--          :disabled="multiple"-->
<!--          @click="handleDelete"-->
<!--          v-hasPermi="['device:secret:remove']"-->
<!--        >废弃</el-button>-->
<!--      </el-col>-->
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['device:secret:add']"
        >追加</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          :disabled="multiple"
          @click="handleExport"
          v-hasPermi="['device:secret:export']"
        >导出</el-button>
      </el-col>
<!--      <right-toolbar :showSearch.sync="showSearch" @queryTable="getPage"></right-toolbar>-->
    </el-row>

    <el-table v-loading="loading" :data="secretList" :row-key="getRowKeys" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="设备编号" align="center" prop="deviceNumber" />
      <el-table-column label="设备密钥" align="center" prop="deviceSecret" />
      <el-table-column label="绑定二维码" align="center" width="120">
        <template slot-scope="scope">
<!--          <i v-hasPermi="['device:secret:bindCode']" class="el-icon-view" @click="showBindCode(scope.row)" style="cursor: pointer"/>-->
<!--          <vue-qr v-hasPermi="['device:secret:bindCode']" :text="scope.row.bindCode" @click="hideBindCode(scope.row)" :correctLevel="0" slot="reference" :size="80" :margin="0" />-->
          <vue-qr v-if="$auth.hasPermi('device:secret:bindCode')" :text="scope.row.bindCode" :correctLevel="0" slot="reference" :size="80" :margin="0" />
          <i v-else class="el-icon-lock"/>
        </template>
      </el-table-column>
      <el-table-column label="启用时间" align="center" prop="enableTime" />
      <el-table-column label="系列编号" align="center" prop="modelMask" />
      <el-table-column label="型号掩码" align="center" prop="typeMask" />
<!--      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--            class="text-warning"-->
<!--            size="mini"-->
<!--            type="text"-->
<!--            icon="el-icon-delete"-->
<!--            @click="handleDelete(scope.row)"-->
<!--            v-hasPermi="['device:secret:remove']"-->
<!--          >废弃</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>

    <!-- 批量生成编号密钥对对话框 -->
    <el-dialog title="批量生成编号密钥对" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="generateForm" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="数量" prop="count">
          <el-input-number v-model="addForm.count" :controls="false" :min="1" :precision="0" :max="1000" placeholder="请输入追加数量" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-row :gutter="60" style="margin-top: -10px">
          <el-col :span="12">
            <el-button type="primary" style="width: 100%" @click="submitAdd">确 定</el-button>
          </el-col>
          <el-col :span="12">
            <el-button @click="open = false" style="width: 100%">取 消</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getPage"
    />
  </div>
</template>

<script>
import { pageSecret, delSecret,generateQuery, append } from "@/api/device/secret";
import { parseTime } from '@/utils/seed'
import vueQr from 'vue-qr'

export default {
  components: { vueQr },
  name: "Secret",
  data() {
    return {
      bindCode: '',
      bindCodeShow: false,
      open: false,
      // 遮罩层
      loading: true,
      generateId: null,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备编号密钥对表格数据
      secretList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        generateId: null,
        ids: []
      },
      addForm: {
        count: 0
      },
      addFormRules: {
        count: [
          { required: true, message: "请追加生成个数", trigger: "blur" }
        ],
      },
      generateInfo: '',
    };
  },
  created() {
    this.generateId = this.$route.params && this.$route.params.generateId;
    this.getPage();
  },
  methods: {
    showBindCode(row){
      row.codeShow = true
    },
    hideBindCode(row){
      console.log(row)
      row.codeShow = false
    },
    /** 查询生成详情 **/
    findGenerate(generateId) {
      generateQuery(generateId).then(res => {
        let generateDesc = '';
        this.generateInfo = res.data;
        if (res.data.type === 'DEV'){
          generateDesc = res.data.modelName + '研发第' + parseInt(res.data.numberPre.slice(-3,-1)) + '版';
        }else {
          generateDesc = res.data.companyName + res.data.modelName + '系列' + res.data.typeName + '类型';
          let yearMonth = res.data.numberPre.slice(-5).slice(0,3);
          generateDesc += yearMonth.slice(0, 2) + '年' + parseInt(yearMonth.slice(2), 16) + '月';
          generateDesc += '第' + res.data.numberPre.slice(-2) + '批次';
        }
        this.generateInfo.desc = generateDesc
        // for (let i = 0; i < this.secretList.length; i++) {
        //   this.secretList[i].modelCode = this.generateInfo.modelCode;
        //   this.secretList[i].typeCode = this.generateInfo.typeCode
        // }
      })
    },
    /** 查询设备编号密钥列表 */
    getPage() {
      this.loading = true;
      this.queryParams.generateId = this.generateId;
      pageSecret(this.queryParams).then(response => {
        this.secretList = response.rows;
        this.total = response.total;
        this.loading = false;
        this.findGenerate(this.generateId);
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getPage();
    },
    /** 搜索按钮操作 */
    handleAdd() {
      this.open = true
      this.addForm.count = 0
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    getRowKeys(row) {
      return row.id;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除数据项？').then(function() {
        return delSecret(ids);
      }).then(() => {
        this.getPage();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.queryParams.ids = this.ids;
      let fileName = this.generateInfo.numberPre + "_" + ((this.ids.length + '').padStart(3,'0')) + "_" + parseTime(new Date(), '{y}{m}{d}_{h}{i}{s}');
      this.download('device/secret/export', {
        ...this.queryParams
      }, `${fileName}.csv`)
    },
    submitAdd(){
      this.addForm.generateId = this.generateId
      append(this.addForm).then(response => {
        this.getPage();
        this.$modal.msgSuccess("追加成功");
        this.open = false
      });
    }
  }
};
</script>
